// Layout.js
import React from 'react';
import { AppBar, Toolbar, Typography, Container, CssBaseline, Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from './logo'; // Make sure to import the correct path to your Logo component

const Layout = ({ children }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '10%'}}>
      <CssBaseline />
      <div style={{ display: 'flex', marginLeft:'70%' ,flexDirection:"column",margin:'1px',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
            

            {/* Move the Logo component to the right */}
            <Logo src="/Arete(1).png" alt="Logo" style={{ height: '110px', width: '100px',marginTop:'0.5%' }} />
          </div>
      <AppBar position="static"  style={{display:'flex',flexDirection:'column', justifyContent:'space-around', backgroundColor: '#fff',width:'100%' }}>
        <Toolbar style={{ justifyContent: 'center',paddingTop: '1px', paddingBottom: '1px',width:'100%'}}>
          

          {/* Add sections with navigation links */}
          <Button component={Link} to="/" color="inherit" style={{color:'black'}}>Home</Button>
          <Button component={Link} to="/Second" color="inherit" style={{color:'black'}}>About</Button>
          <Button component={Link} to="/Contact" color="inherit" style={{color:'black'}}>Contact</Button>
          {/* Add more sections as needed */}
        </Toolbar>
        
      </AppBar>
      <Container component="main" style={{ flexGrow: 1, marginTop: '20px' ,}}>
        <Paper elevation={3} style={{ padding: '20px', margin:'2px' }}>
          {children}
        </Paper>
      </Container >
      <footer style={{ backgroundColor: '#f8f8f8', padding: '10px', textAlign: 'center', bottom: 0, marginTop:'10px' }}>
        <Typography variant="body2" color="textSecondary">
          Arete soluciones digitales. Web developed and designed by Joaquin Avaro Acosta &copy; {new Date().getFullYear()}
        </Typography>
      </footer>
    </div>
  );
};

export default Layout;
