
import './App.css';
import Layout from './components/layout';
import Carousel from './components/carousel';
import ResponsiveContainer from './components/responsivecontainer';
import ModernText from './components/moderntext';

function App() {
  const customImages = [
    { src: '/Plan presencia.png', alt: 'Custom Image 1', text: 'Nuestro Plan presencia digital te ayudara a posicionarte en el mundo digital obteniendo beneficios inmediatos para tu negocio!' },
    { src: '/MarzoAbril.png', alt: 'Custom Image 2', text: 'Web site o landing-page + presencia en redes sociales y 8 publicaciones + 4 diseño de piezas de marketing digital!  ' },
    { src: '/Servicio.png', alt: 'Custom Image 3', text: 'Un servicio personalizado muchas veces es fundamental para tu negocio, no dudes en contactarnos por otro tipo de servicio que requieras' }
  ];
  return (
    <Layout>
      <h1>Inicio</h1>
    <div className="App">
    <Carousel/>
      <header className="App-header">
      <ModernText>
        <h3>¡Bienvenidos a Arete!</h3>
        <p>

En Arete, nos enorgullecemos de ofrecer servicios digitales de primera calidad para ayudar a tu negocio a alcanzar su máximo potencial en el mundo en línea. Nuestro nombre, "Arete", proviene de un antiguo concepto griego que representa la excelencia y la virtud, valores que nos esforzamos por reflejar en todo lo que hacemos.

Ya sea que necesites desarrollar una presencia en línea atractiva con un sitio web de vanguardia, aumentar tu presencia en las redes sociales con una gestión comunitaria efectiva, o impulsar tus ventas con estrategias de marketing digital innovadoras, nuestro equipo altamente calificado está aquí para ayudarte.</p>
      </ModernText>

        <ResponsiveContainer  images={customImages}/>
        
       
       
       
      </header>
      
    </div>
    </Layout>
  );
}

export default App;
