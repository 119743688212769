import React from 'react';
import Layout from '../components/layout';
import FlipResponsiveContainer from '../components/flipcontainer';
import TextContainer from '../components/textContainer';



function Second() {
  
  return (
    <Layout>
      <h1>Sobre nosotros y nuestros servicios</h1>
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
    
      
      
      <FlipResponsiveContainer
  cards={[
    {
      frontImageSrc: './Plan presencia.png',
      alt: 'Image 1',
      frontText: '',
      backText: 'Website + presencia en redes sociales(creacion de contenido 1 mes) + 4piezas de marketing digital = €899'
    },
    {
      frontImageSrc: 'RedesSociales.png',
      alt: 'Image 2',
      frontText: '',
      backText: 'Plan social-media + Perfil profesional en redes sociales y creacion contenido 1 mes= €299'
    },
    {
      frontImageSrc: 'marketing.png',
      alt: 'Image 3',
      frontText: '',
      backText: 'Plan digital-marketing y ejecucion = €299 o pieza publicitaria = €50'
    },
    {
      frontImageSrc: 'Atencion al cliente.png',
      alt: 'Image 4',
      frontText: '',
      backText: 'Atencion al cliente: Email, redes sociales, whatsap = €800 por turno. Consultar detalles. '
    },
    {
      frontImageSrc: 'Desarrolloweb.png',
      alt: 'Image 4',
      frontText: ' ',
      backText: 'Landing page = €199 , Website = 299 , e-commerce = 399, blog = 399. Consultar por websites personalizadas'
    },
    // Add more objects as needed for additional cards
  ]}
/>



    </div>

    <TextContainer>
      
        <p>No tengas dudas en contactarnos si necesitas otro tipo de servicios, ofrecemos estrategias personalizadas  y servicios de temporada.</p>
        
        <p>Te esperamos!</p>
      </TextContainer>
    
    
    </Layout>
  );
};

export default Second;