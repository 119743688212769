import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Grid } from '@mui/material';
import './formcontact.css'

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://areteserver-production.up.railway.app/newinterested', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Reset form fields after successful submission
      setFormData({ name: '', email: '', message: '' });
      alert('Form submitted successfully!');
      console.log(formData); // You can replace this with your logic to submit the form data
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form. Please try again later.');
    }

  };

  return (
    <Container maxWidth="md" className="contact-form-container">
      <Typography variant="h4" align="center" gutterBottom>Contactanos utilizando este formulario y obtene 10% de descuento en todos nuestros servicios!</Typography>
      <form onSubmit={handleSubmit} className="contact-form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              variant="outlined"
              className="contact-form-field"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              variant="outlined"
              className="contact-form-field"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              variant="outlined"
              multiline
              rows={4}
              className="contact-form-field"
            />
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" color="primary" fullWidth className="contact-form-submit">
          Enviar
        </Button>
      </form>
    </Container>
  );
};

export default ContactForm;
